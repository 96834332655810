import autosize from "autosize";
import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const TextareaWrapper = styled.span`
  textarea {
    min-height: 50px;
    color: black;
    border: 1px solid #d5d5d5;
    border-radius: 0;
    box-shadow: none !important;
    padding: 5px 4px 6px;

    :hover {
      border-color: #b5b5b5;
    }
    :focus {
      border-color: #f59942;
      outline: none;
      color: black;
    }

    ::placeholder {
      color: #777777;
      font-style: italic;
    }
  }

  .textarea-counter {
    display: block;
    color: #777;
    font-size: 13px;
  }
`;

export interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  /**If set to true then the textarea box will dynamically change its height */
  autoSize?: boolean;
  /**If set to true then the textarea box will have a width equal to that of the container it is in */
  formControl?: boolean;
}
export type TextareaState = {
  charLeftCount: number | undefined;
};

export const Textarea: React.FunctionComponent<TextareaProps> = (
  props: TextareaProps
): JSX.Element => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [remainingCharsCount, setRemainingCharsCount] = useState<number>(0);

  // Set to autosize if prop exists
  useEffect(() => {
    if (props.autoSize && textareaRef.current) {
      autosize(textareaRef.current);
    }
  }, [props.autoSize]);

  // Set initial Remaining characters count
  useEffect(() => {
    if (props.maxLength) {
      const initialTextAreaLength = textareaRef?.current?.value?.length ?? 0;
      setRemainingCharsCount(props.maxLength - initialTextAreaLength);
    }
  }, [props.maxLength, setRemainingCharsCount]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Recalculate remaining characters on change
    if (props.maxLength && textareaRef.current) {
      setRemainingCharsCount(
        props.maxLength - textareaRef.current.value.length
      );
    }

    // Pass event on to change handler
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const { autoSize, formControl, className, ...other } = props;
  return (
    <TextareaWrapper>
      <textarea
        {...other}
        className={classNames(className, { "form-control": formControl })}
        ref={textareaRef}
        onChange={handleChange}
      />
      {props.maxLength && (
        <span className="textarea-counter">
          {remainingCharsCount} characters left
        </span>
      )}
    </TextareaWrapper>
  );
};

export default Textarea;

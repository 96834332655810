import * as React from "react";
import styled from "styled-components";
import { default as ReactDatePicker } from "react-date-picker/dist/entry.nostyle";
import { DatePickerProps as DatePickerPackageProps } from "react-date-picker";
import classNames from "classnames";

const DatePickerWrapper = styled.div<DatePickerProps & { xOffset: number }>`
  .react-date-picker {
    display: inline-flex;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    & *,
    & *:before,
    & *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    &.react-date-picker--disabled {
      background-color: #f0f0f0;
      color: #6d6d6d;
    }

    & .react-date-picker__button {
      display: flex;
      border: 0px;

      & > .react-date-picker__button__input:not(:disabled):hover,
      & > .react-date-picker__button__input:not(:disabled):hover,
      & > .react-date-picker__button__icon:not([disabled]):hover,
      & > .react-date-picker__button__icon:not([disabled]):hover {
        border-color: #ff893c;
        z-index: 100;
      }

      & > .react-date-picker__button__input:focus,
      & > .react-date-picker__button__icon:focus {
        border-color: #f59942;
      }

      & .react-date-picker__button__input {
        min-width: calc(4px + (4px * 3) + 4.32em + 0.434em);
        flex-grow: 1;
        display: flex;
        padding: 0 5px;
        border: 1px solid #cccccc;
        margin-right: -1px;
        align-items: baseline;

        .react-date-picker__button__input__divider {
          padding: 1px 0;
        }
        .react-date-picker__button__input__input {
          min-width: calc(4px + 0.54em);
          height: 100%;
          position: relative;
          padding: 1px;
          border: 0;
          background: none;
          -moz-appearance: textfield;

          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &:invalid {
            background: #ffc0d0;
            background: rgba(255, 0, 0, 0.1);
          }

          &.react-date-picker__button__input__input--hasLeadingZero {
            min-width: calc(4px + 1.08em);
            margin-left: -0.54em;
            padding-left: calc(1px + 0.54em);
          }
        }
      }

      & .react-date-picker__clear-button {
        display: none;
      }

      & .react-date-picker__calendar-button {
        &:hover,
        &:focus {
          color: #005eb8;
        }

        &:disabled {
          > i {
            color: #6d6d6d;
          }
        }
      }

      .react-date-picker__button__icon {
        border: 1px solid #cccccc;
        background: #eeeeee;
        padding: 4px 8px;

        &:enabled {
          cursor: pointer;

          & svg {
            display: inherit;
          }
        }
      }
    }

    .react-date-picker__calendar {
      position: absolute;
      width: 300px;
      max-width: 100vw;
      padding: 4px;
      margin-top: 40px;
      margin-left: ${props => props.xOffset * -1 + -5 + "px"};
      z-index: 150;
      background: #ffffff;
      border: 1px solid #cccccc;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

      &:before {
        content: "\\25B2";
        display: block;
        position: absolute;
        top: -14px;
        left: ${props => props.xOffset + 36 + "px"};
        color: #ffffff;
        text-shadow: 0px -3px 0px rgba(0, 0, 0, 0.2);
      }

      &.react-date-picker__calendar--closed {
        display: none;
      }
      &.react-date-picker__calendar--above-label {
        bottom: 100%;
        top: auto;
      }

      & .react-calendar {
        border: 1px thin #a0a096;
        background: #ffffff;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 1.125em;

        & button {
          margin: 0;
          border: 0;
          outline: none;

          &:enabled:hover {
            cursor: pointer;
          }
        }

        & > .react-calendar__navigation {
          height: 30px;
          margin-bottom: 0;
          font-weight: 700;

          & button {
            min-width: 30px;
            background: none;
            padding-bottom: 5px;

            &:enabled {
              &:hover,
              &:focus {
                background: #e6e6e6;
              }
            }

            &[disabled],
            &:disabled {
              background: #f0f0f0;
            }

            &.react-calendar__navigation__arrow {
              font-size: 1.1em;
            }
            &.react-calendar__navigation__label {
              font-size: 0.9em;
              line-height: 28px;
            }

            &.react-calendar__navigation__prev2-button,
            &.react-calendar__navigation__next2-button {
              display: none !important;
            }
          }
        }

        & > .react-calendar__month-view {
          & .react-calendar__month-view__weekdays {
            text-align: center;
            font-weight: 700;
            font-size: 0.875em;

            & .react-calendar__month-view__weekdays__weekday {
              padding: 0.4em 0.2em 0.4em 0.5em;
            }
          }

          & .react-calendar__month-view__days__day--neighboringMonth {
            color: #969696;
          }
        }

        & .react-calendar__year-view .react-calendar__tile,
        & .react-calendar__decade-view .react-calendar__tile,
        & .react-calendar__century-view .react-calendar__tile {
          padding: 2em 0.5em;
        }

        & .react-calendar__tile {
          max-width: 100%;
          font-size: 0.95em;
          font-weight: 400;
          text-align: center;
          padding: 0.5em 0.2em;
          background: none;

          &:disabled,
          &[disabled] {
            background-color: #f0f0f0;
          }

          &:enabled {
            &:hover,
            &:focus {
              background: #ececec;
            }
          }
        }

        & .react-calendar__tile--hasActive {
          background: #76baff;
          &:enabled {
            &:hover,
            &:focus {
              background: #a9d4ff;
            }
          }
        }

        .react-calendar__tile--active {
          background: #005eb8;
          color: #ffffff;

          &:enabled {
            &:hover,
            &:focus {
              background: #1087ff;
            }
          }
        }
      }
    }
  }
`;

export interface DatePickerProps extends DatePickerPackageProps {
  /** optional attribute for setting additional classes on the DatePickerWrapper */
  containerClassName?: string;
}

interface DatePickerState {
  xOffset: number;
}

export default class DatePicker extends React.Component<
  DatePickerProps,
  DatePickerState
> {
  public datePicker: React.RefObject<any> = React.createRef();

  static defaultProps = {
    locale: "en-US",
    minDetail: "decade",
    calendarIcon: <i className="fa fa-calendar" />
  };

  state: DatePickerState = {
    xOffset: 0
  };

  componentDidMount() {
    this.calculateOffset();
  }

  calculateOffset = () => {
    const windowWidth = window.innerWidth;
    const dpDomRect = this.datePicker.current.getBoundingClientRect();
    const leftDisp = dpDomRect.left;
    const distanceFromRight = windowWidth - leftDisp;

    if (distanceFromRight < 300) {
      let xOffset = Math.abs(distanceFromRight - 300);
      this.setState({ xOffset });
    } else {
      this.setState({ xOffset: 0 });
    }
  };

  render() {
    var {
      className,
      calendarClassName,
      containerClassName,
      ...other
    } = this.props;

    return (
      <DatePickerWrapper
        className={classNames("date-picker-wrapper", containerClassName)}
        ref={this.datePicker}
        onClick={this.calculateOffset}
        xOffset={this.state.xOffset}
      >
        <ReactDatePicker
          className={classNames("hcss-date-picker", className)}
          calendarClassName={classNames(
            "hcss-date-picker-calendar",
            calendarClassName
          )}
          {...other}
        />
      </DatePickerWrapper>
    );
  }
}

import * as React from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import { debounce } from "lodash";
import {
  SectionListModel,
  ScrollOffsetListModel
} from "./ContentSectionDataModels";
import ContentMain from "./ContentMain";
import ContentAside, {
  ContentAsideTemplate,
  isContentAsideTemplate
} from "./ContentAside";
import ContentSection, {
  ContentSectionTemplate,
  isContentSectionTemplate
} from "./ContentSection";
import SectionTableOfContents, {
  SectionTableOfContentsTemplate,
  isTableOfContentsTemplate
} from "./SectionTableOfContents";
import {
  PaneledPageContext,
  PaneledPageContextState
} from "./PaneledPageContext";
import { PaneledPageExtendedProps } from "./ExtendedProps";
import * as ConcreteColors from "../../ConcreteColors";

const getContentSectionListFromChildren = (
  children?: React.ReactNode | React.ReactNode[]
): SectionListModel[] => {
  if (children) {
    let sections: SectionListModel[] = [];
    let contentSectionCount: number = 0;
    React.Children.forEach(children, (node, index) => {
      if (isContentSectionTemplate(node)) {
        sections.push({
          sectionIndex: contentSectionCount,
          sectionTitle: (node as ContentSectionTemplate).props.title,
          sectionFlagged: (node as ContentSectionTemplate).props.flagged
        });
        contentSectionCount++;
      }
    });
    return sections;
  } else return [] as SectionListModel[];
};

const checkForTableOfContents = (
  children?: React.ReactNode | React.ReactNode[]
): boolean => {
  let tableOfContentsFound = false;

  if (children) {
    let numTOCChildNodes: number = 0;
    const childArray = React.Children.toArray(children);
    for (let index = 0; index < childArray.length; index++) {
      const node = childArray[index];
      if (isTableOfContentsTemplate(node)) {
        if (process.env.NODE_ENV === "development") {
          tableOfContentsFound = true;
          numTOCChildNodes++;
        } else {
          return true;
        }
      }
    }

    if (numTOCChildNodes > 1) {
      // No need to print more than one warning message if, for some bizarre reason, there was
      // an attempt to mount more than two <PaneledPage.Content.TableOfContents> components.
      console.warn(
        "Invalid number of <PaneledPage.Content.TableOfContents> inside PaneledPage content\n" +
          "No more than one <PaneledPage.Content.TableOfContents> component should be used simultaneously in a PaneledPage's content."
      );
    }
  }

  return tableOfContentsFound;
};

const checkForAside = (
  children?: React.ReactNode | React.ReactNode[]
): boolean => {
  let asideFound: boolean = false;

  if (children) {
    let numAsideChildNodes: number = 0;
    const childArray = React.Children.toArray(children);
    for (let index = 0; index < childArray.length; index++) {
      const node = childArray[index];
      if (isContentAsideTemplate(node)) {
        if (process.env.NODE_ENV === "development") {
          asideFound = true;
          numAsideChildNodes++;
        } else {
          return true;
        }
      }
    }

    if (numAsideChildNodes > 1) {
      // No need to print more than one warning message if, for some bizarre reason, there was
      // an attempt to mount more than two <PaneledPage.Content.TableOfContents> components.
      console.warn(
        "Invalid number of <PaneledPage.Content.Aside> inside PaneledPage content\n" +
          "No more than one <PaneledPage.Content.Aside> component should be used simultaneously in a PaneledPage's content."
      );
    }
  }

  return asideFound;
};

const refreshSectionFlagging = (
  currentSectionList: SectionListModel[],
  children?: React.ReactNode | React.ReactNode[]
): SectionListModel[] => {
  if (children) {
    let updatedSectionList: SectionListModel[] = [...currentSectionList];
    let contentSectionCount: number = 0;
    React.Children.forEach(children, (node, index) => {
      if (isContentSectionTemplate(node)) {
        updatedSectionList[
          contentSectionCount
        ].sectionFlagged = (node as ContentSectionTemplate).props.flagged;
        contentSectionCount++;
      }
    });
    return updatedSectionList;
  } else return [] as SectionListModel[];
};

export interface PageContentPanelTemplateProps
  extends PaneledPageExtendedProps {
  /** When set to true, closes an open filters panel when the user clicks inside the content. */
  hideFiltersOnContentClicked?: boolean;
  /** Automation testing id for the PaneledPage.Content component. Defaults to "paneledpage-content". */
  testId?: string;
  /** Automation testing id for the Content.Main component. Defaults to "paneledpage-content-main". */
  mainContainerTestId?: string;
  /** Automation testing id for the inner content wrapper, which sits inside the Content.Main component, and which contains the actual content.
   * Defaults to "paneledpage-content-main-inner-wrapper". */
  contentInnerWrapperTestId?: string;
  /** Child elements of the PaneledPage.Content component. These can be anything, including the
   * PaneledPage.Content.Section component and the PaneledPage.Content.Aside component.
   * When using the PaneledPage.Content.Aside component, be sure to use only one, and mount it as
   * the first child of the PaneledPage.Content. */
  children?: React.ReactNode | React.ReactNode[];
}
export default class PageContentPanelTemplate extends React.PureComponent<
  PageContentPanelTemplateProps
> {
  static Section = ContentSectionTemplate;
  static Aside = ContentAsideTemplate;
  static TableOfContents = SectionTableOfContentsTemplate;

  render() {
    return (
      <PaneledPageContext.Consumer>
        {(pageContext: PaneledPageContextState) => (
          <PageContentPanel pageContext={pageContext} {...this.props} />
        )}
      </PaneledPageContext.Consumer>
    );
  }
}

export interface PageContentPanelProps extends PageContentPanelTemplateProps {
  pageContext: PaneledPageContextState;
}
export interface PageContentPanelState {
  allSections: SectionListModel[];
  sectionScrollOffsets: ScrollOffsetListModel[];
  currentSectionIndex: number | null;
  contentScrollPosition: number;
  contentResizingCalcInProgress: boolean;
  currentWidth: number;
  fillerHeight: number;
  fillerWasResized: boolean;
  userRequestedScrollToSection: boolean;
  hasContentAside: boolean;
  hasTableOfContents: boolean;
}

const defaultScrollUpBuffer: number = 74;
const defaultScrollDownBuffer: number = 56;

export class PageContentPanel extends React.PureComponent<
  PageContentPanelProps,
  PageContentPanelState
> {
  private mainContainerRef: React.RefObject<any> = React.createRef();
  readonly state: PageContentPanelState = {
    allSections: getContentSectionListFromChildren(this.props.children),
    sectionScrollOffsets: [],
    currentSectionIndex: null,
    contentScrollPosition: 0,
    contentResizingCalcInProgress: false,
    currentWidth: 0,
    fillerHeight: 0,
    fillerWasResized: false,
    userRequestedScrollToSection: false,
    hasContentAside: checkForAside(this.props.children),
    hasTableOfContents: checkForTableOfContents(this.props.children)
  };

  componentDidMount() {
    let updatedScrollOffsets: ScrollOffsetListModel[] = this.getUpdatedSectionScrollOffsets(
      this.props.children,
      0
    );
    let updatedWidth: number = this.calculateWidth();

    this.setState(
      {
        allSections: getContentSectionListFromChildren(this.props.children),
        contentScrollPosition: 0,
        currentWidth: updatedWidth,
        sectionScrollOffsets: updatedScrollOffsets,
        userRequestedScrollToSection: false,
        hasContentAside: checkForAside(this.props.children),
        hasTableOfContents: checkForTableOfContents(this.props.children)
      },
      () => {
        if (this.state.hasContentAside && this.state.hasTableOfContents) {
          console.warn(
            "Invalid simultaneous use of children <PaneledPage.Content.Aside> and <PaneledPage.Content.TableOfContents>\n" +
              "<PaneledPage.Content.Aside> and <PaneledPage.Content.TableOfContents> components should not be used simultaneously in a PaneledPage's content."
          );
        }
        this.setState(prevState => ({
          currentSectionIndex:
            prevState.allSections && prevState.allSections.length > 0
              ? prevState.allSections[0].sectionIndex
              : null
        }));
      }
    );

    window.addEventListener("resize", this.handleWindowResize, true);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize, true);
  }

  componentDidUpdate(prevProps: PageContentPanelProps) {
    if (prevProps.children !== this.props.children)
      this.setState({
        allSections: refreshSectionFlagging(
          this.state.allSections,
          this.props.children
        )
      });
  }

  handleMainContainerMounted = () => {
    let fillerHeight: number = this.calculateFillerHeight();
    let updatedScrollOffsets: ScrollOffsetListModel[] = this.getUpdatedSectionScrollOffsets(
      this.props.children,
      0
    );

    this.setState(prevState => ({
      sectionScrollOffsets: updatedScrollOffsets,
      currentSectionIndex:
        prevState.allSections && prevState.allSections.length > 0
          ? prevState.allSections[0].sectionIndex
          : null,
      fillerHeight: fillerHeight
    }));
  };

  handleWindowResize = (event: any) => {
    this.setState({
      currentWidth: this.calculateWidth(),
      fillerHeight: this.calculateFillerHeight()
    });
  };

  calculateWidth = (): number => {
    let selfElement: Element = ReactDOM.findDOMNode(this) as Element;
    return selfElement ? selfElement.getBoundingClientRect().width : 0;
  };

  calculateFillerHeight = (): number => {
    let allSections: SectionListModel[] = this.state.allSections;
    if (allSections && allSections.length > 0) {
      let container: Element | Text | null = ReactDOM.findDOMNode(
        this.mainContainerRef.current
      );
      let lastSection: Element | null = (ReactDOM.findDOMNode(
        this
      ) as Element).querySelector(
        `.content-section[data-section-index="${
          allSections[allSections.length - 1].sectionIndex
        }"]`
      );

      if (container && lastSection) {
        let containerHeight: number = (container as Element).getBoundingClientRect()
          .height;
        let lastSectionHeight: number = (lastSection as Element).getBoundingClientRect()
          .height;
        let baseMargin: number = 82; // Takes into account the 32px bottom margin underneath each section
        return lastSectionHeight + baseMargin <= containerHeight
          ? containerHeight - (lastSectionHeight + baseMargin)
          : 0;
      } else return 0;
    } else return 0;
  };

  calculateScrollUpBuffer = (): number => {
    let container: Element | Text | null = ReactDOM.findDOMNode(
      this.mainContainerRef.current
    );
    return container
      ? 0.68 * (container as Element).getBoundingClientRect().height
      : 0;
  };

  calculateScrollDownBuffer = (): number => {
    let container: Element | Text | null = ReactDOM.findDOMNode(
      this.mainContainerRef.current
    );
    return container
      ? 0.52 * (container as Element).getBoundingClientRect().height
      : 0;
  };

  getUpdatedSectionScrollOffsets = (
    childNodes: any,
    updatedScrollPosition: number
  ): ScrollOffsetListModel[] => {
    let sectionScrollOffsets: ScrollOffsetListModel[] = [];

    if (this.mainContainerRef) {
      let container: Element = ReactDOM.findDOMNode(
        this.mainContainerRef.current
      ) as Element;
      let contentSectionCount: number = 0;

      React.Children.forEach(childNodes, (node, index) => {
        if (isContentSectionTemplate(node)) {
          let section: Element | null = container
            ? (container.querySelector(
                `.content-section[data-section-index="${contentSectionCount}"]`
              ) as Element)
            : null;
          let scrollOffset: number = section
            ? Math.abs(
                Math.round(
                  section.getBoundingClientRect().top -
                    (container.getBoundingClientRect().top +
                      12 -
                      updatedScrollPosition)
                )
              )
            : -1;

          sectionScrollOffsets.push({
            sectionIndex: contentSectionCount,
            sectionScrollOffset: scrollOffset
          });

          contentSectionCount++;
        }
      });
    }
    return sectionScrollOffsets;
  };

  getCurrentSectionWithUpdatedPosition = (
    currentScrollPosition: number,
    updatedScrollOffsets: ScrollOffsetListModel[],
    scrollChange?: number
  ): number | null => {
    let lastPassedSectionIndex: number | null = null;
    let scrollUpBuffer: number = this.calculateScrollUpBuffer();
    let scrollDownBuffer: number = this.calculateScrollDownBuffer();

    const triggerInterferenceWithPreviousSections = (
      currentIndex: number,
      currentSectionOffset: number,
      calculatedBuffer: number,
      defaultBuffer: number
    ): boolean => {
      if (currentSectionOffset < calculatedBuffer) return true;
      else if (
        currentSectionOffset <
        2 * calculatedBuffer - defaultBuffer + 100
      ) {
        let allSections: SectionListModel[] = this.state.allSections;
        let previousSection: Element | null =
          allSections && allSections.length > 0
            ? (ReactDOM.findDOMNode(this) as Element).querySelector(
                `.content-section[data-section-index="${currentIndex - 1}"]`
              )
            : null;
        let previousSectionHeight: number = previousSection
          ? previousSection.getBoundingClientRect().height
          : -1;
        if (previousSectionHeight > 0)
          return calculatedBuffer > 0
            ? previousSectionHeight < calculatedBuffer
            : previousSectionHeight < defaultBuffer;
        else return true;
      } else return false;
    };

    if (updatedScrollOffsets && updatedScrollOffsets.length > 0) {
      let index: number = 0;
      let passedPreviousTrigger: boolean = true;
      while (index < updatedScrollOffsets.length && passedPreviousTrigger) {
        let lowTrigger: number;
        let sectionAtIndex: ScrollOffsetListModel = updatedScrollOffsets[index];

        if (index === 0) lowTrigger = 0;
        else {
          let sectionScrollOffset: number = sectionAtIndex.sectionScrollOffset;
          if (scrollChange && scrollChange < 0) {
            lowTrigger =
              scrollUpBuffer &&
              !triggerInterferenceWithPreviousSections(
                index,
                sectionScrollOffset,
                scrollUpBuffer,
                defaultScrollUpBuffer
              )
                ? sectionScrollOffset - scrollUpBuffer
                : sectionScrollOffset - defaultScrollUpBuffer;
          } else {
            lowTrigger =
              scrollDownBuffer &&
              !triggerInterferenceWithPreviousSections(
                index,
                sectionScrollOffset,
                scrollDownBuffer,
                defaultScrollDownBuffer
              )
                ? sectionScrollOffset - scrollDownBuffer
                : sectionScrollOffset - defaultScrollDownBuffer;
          }
        }

        if (lowTrigger <= currentScrollPosition)
          lastPassedSectionIndex = sectionAtIndex.sectionIndex;
        else passedPreviousTrigger = false;
        index++;
      }
    }

    return lastPassedSectionIndex !== null
      ? lastPassedSectionIndex
      : this.state.currentSectionIndex;
  };

  handleClickInside = (event: any) => {
    this.props.pageContext.onClickInsideContent(
      this.props.hideFiltersOnContentClicked || true
    );
  };

  handleScrollContent = (event: any, updatedScrollPosition: number) => {
    if (!this.state.fillerWasResized) {
      let scrollChange: number = this.state.userRequestedScrollToSection
        ? 0
        : updatedScrollPosition - this.state.contentScrollPosition;
      let updatedSectionIndex: number | null = !this.state
        .userRequestedScrollToSection
        ? this.getCurrentSectionWithUpdatedPosition(
            updatedScrollPosition,
            this.state.sectionScrollOffsets,
            scrollChange
          )
        : this.state.currentSectionIndex;
      this.setState({
        contentScrollPosition: updatedScrollPosition,
        currentSectionIndex: updatedSectionIndex
      });
      this.delayUserScrollRequestReset();
    }
  };

  delayUserScrollRequestReset = debounce(() => {
    this.setState({ userRequestedScrollToSection: false });
  }, 200);

  delayFillerResizeStatusReset = debounce(() => {
    this.setState({ fillerWasResized: false });
  }, 200);

  handleSectionChangedHeight = (isLastSection: boolean) => {
    if (isLastSection)
      this.setState(
        {
          fillerHeight: this.calculateFillerHeight(),
          fillerWasResized: true
        },
        this.delayFillerResizeStatusReset
      );
    else
      this.setState({
        sectionScrollOffsets: this.getUpdatedSectionScrollOffsets(
          this.props.children,
          this.state.contentScrollPosition
        )
      });
  };

  handleSelectSection = debounce((selectedSectionIndex: number) => {
    if (this.state.sectionScrollOffsets) {
      let sectionPosition = this.state.sectionScrollOffsets[
        selectedSectionIndex
      ].sectionScrollOffset;
      this.mainContainerRef.current.scrollTop =
        sectionPosition > defaultScrollDownBuffer
          ? sectionPosition - defaultScrollDownBuffer
          : 0;
      this.setState({
        userRequestedScrollToSection: true,
        currentSectionIndex: selectedSectionIndex
      });
    }
  }, 250);

  render() {
    let contentSectionCount: number = 0;

    return (
      <StyledPageContentPanel
        className="page-content-panel"
        data-modal-overlay-active={this.props.pageContext.filtersPanelExpanded}
        data-has-content-aside={this.state.hasContentAside}
        data-has-table-of-contents={this.state.hasTableOfContents}
        data-content-align={this.props.pageContext.contentAlign}
        maxpagewidth={this.props.pageContext.maxPageWidth as any}
        containerwidth={this.state.currentWidth as any}
        asidewidth={this.props.pageContext.asideWidth as any}
        fillerheight={this.state.fillerHeight as any}
        onClick={this.handleClickInside}
        data-testid={this.props.testId || "paneledpage-content"}
        {...this.props.passThroughProps}
      >
        {React.Children.map(this.props.children, (node, index) => {
          // Render only ContentAside and TableOfContents child elements outside the main container
          if (isContentAsideTemplate(node)) {
            return (
              <ContentAside
                {...node.props}
                key={index}
                pageContext={this.props.pageContext}
              />
            );
          } else if (isTableOfContentsTemplate(node)) {
            return (
              <SectionTableOfContents
                {...node.props}
                key={index}
                allSections={this.state.allSections}
                currentSectionIndex={this.state.currentSectionIndex}
                onSelectSection={this.handleSelectSection}
              />
            );
          }
          return null;
        })}
        <ContentMain
          mainContainerRef={this.mainContainerRef}
          onScroll={this.handleScrollContent}
          onMount={this.handleMainContainerMounted}
        >
          {React.Children.map(this.props.children, (node, index) => {
            const numSections: number = this.state.allSections.length;
            if (node && isContentSectionTemplate(node)) {
              contentSectionCount++;
              const sectionIndex: number = contentSectionCount - 1;
              let isLast: boolean = sectionIndex === numSections - 1;
              return (
                <ContentSection
                  {...node.props}
                  key={index}
                  index={sectionIndex}
                  isCurrentSection={
                    sectionIndex === this.state.currentSectionIndex
                  }
                  isLastSection={isLast}
                  onHeightChange={this.handleSectionChangedHeight}
                  tableOfContentsActive={this.state.hasTableOfContents}
                />
              );
              // prevent render of ContentAside and Table of Contents child elements inside the Main container
            } else if (
              !isContentAsideTemplate(node) &&
              !isTableOfContentsTemplate(node)
            )
              return node;

            return null;
          })}
        </ContentMain>
      </StyledPageContentPanel>
    );
  }
}

export const StyledPageContentPanel = styled.div<{
  maxpagewidth: number | null;
  containerwidth: number;
  asidewidth: number;
  fillerheight: number | null;
}>`
  display: flex;
  flex: 1 0;
  flex-wrap: nowrap;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  background: ${ConcreteColors.gray200};
  z-index: 0;

  @media print {
    height: auto;
    overflow: visible;
  }

  &[data-has-table-of-contents="true"] .page-content-main {
    & > .content-inner-wrapper {
      padding-bottom: ${props =>
        props.fillerheight ? `${props.fillerheight}px` : 0};
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    transition: 0.2s;
  }
  &[data-modal-overlay-active="true"] {
    .page-content-main {
      overflow: hidden;
    }

    &:before {
      background: ${ConcreteColors.gray400};
      z-index: 5;
    }
  }
  &[data-modal-overlay-active="false"] {
    .page-content-main {
      overflow: auto;
    }

    &:before {
      background: transparent;
      z-index: -5;
    }
  }

  &[data-has-content-aside="true"],
  &[data-has-table-of-contents="true"] {
    &[data-content-align="center"] {
      .page-content-aside {
        left: ${props =>
          props.maxpagewidth && props.maxpagewidth < props.containerwidth
            ? `calc(50% - ${props.maxpagewidth * 0.5}px)`
            : "0"};
      }
      .page-content-main > .content-inner-wrapper {
        margin-left: ${props =>
          props.maxpagewidth && props.maxpagewidth < props.containerwidth
            ? `calc(50% - ${props.maxpagewidth * 0.5 - props.asidewidth}px)`
            : `${props.asidewidth}px`};
        margin-right: ${props =>
          props.maxpagewidth && props.maxpagewidth < props.containerwidth
            ? `calc(50% - ${props.maxpagewidth * 0.5}px)`
            : "0"};
        max-width: ${props =>
          props.maxpagewidth
            ? `${props.maxpagewidth - props.asidewidth}px`
            : "none"};
      }
    }

    &[data-content-align="left"] {
      .page-content-aside {
        left: 0;
      }
      .page-content-main > .content-inner-wrapper {
        margin-left: ${props => `${props.asidewidth}px`};
        margin-right: auto;
        max-width: ${props =>
          props.maxpagewidth
            ? `${props.maxpagewidth - props.asidewidth}px`
            : "none"};
      }
    }
  }

  &[data-has-content-aside="false"][data-has-table-of-contents="false"] {
    .page-content-main > .content-inner-wrapper {
      max-width: ${props =>
        props.maxpagewidth ? `${props.maxpagewidth}px` : "none"};
    }

    &[data-content-align="center"] {
      .page-content-main > .content-inner-wrapper {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &[data-content-align="left"] {
      .page-content-main > .content-inner-wrapper {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }
`;

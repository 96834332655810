import * as React from "react";
import styled from "styled-components";
import { Collapse } from "react-bootstrap";
import { Icon } from "../Icon";
import * as ConcreteColors from "../../ConcreteColors";
import * as AceColors from "../../AceColors";

const SectionWrapper = styled("div")`
  white-space: nowrap;
`;

const SectionHeader = styled("div")`
  background: ${(props: any) =>
    props.theme.baseTheme === "Ace"
      ? AceColors.gray300
      : ConcreteColors.gray300};
  padding: 0.2em 0.5em;
  font-size: 1.2em;
  border-bottom: 1px solid
    ${(props: any) =>
      props.theme.baseTheme === "Ace"
        ? AceColors.gray400
        : ConcreteColors.gray400};
  height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;

  i {
    margin-right: 0.5em;
  }

  i.arrow {
    float: right;
    margin-top: 0.2em;
    transition: transform 0.2s ease-in 0s;

    &.open {
      transform: rotate(90deg);
    }
  }
`;

const SectionBody = styled("div")``;

//Wrapping div around Section Body to fix choppy animations
const SectionCollapse = styled(Collapse)`
  & > div {
    padding: 0.5em;
  }
`;

export interface AsideSectionProps extends React.HTMLProps<HTMLInputElement> {
  title?: string;
  collapse?: boolean;
  iconName?: string;
}

export interface AsideSectionState {
  show: boolean;
}

export default class AsideSection extends React.Component<
  AsideSectionProps,
  AsideSectionState
> {
  constructor(props: AsideSectionProps) {
    super(props);

    this.state = {
      show: !this.props.collapse
    };
  }

  render() {
    return (
      <SectionWrapper>
        <SectionHeader
          onClick={() =>
            this.props.collapse && this.setState({ show: !this.state.show })
          }
        >
          {this.props.iconName && <Icon name={this.props.iconName} />}
          {this.props.title}
          {this.props.collapse && (
            <Icon
              name="chevron-right"
              className={this.state.show ? "arrow open" : "arrow"}
            />
          )}
        </SectionHeader>
        <SectionCollapse in={this.state.show}>
          <div>
            <SectionBody>{this.props.children}</SectionBody>
          </div>
        </SectionCollapse>
      </SectionWrapper>
    );
  }
}

import * as React from "react";
import styled from "styled-components";
import AsideSection from "./AsideSection";
//need to import ace-elements and ace-extra
import * as ConcreteColors from "../../ConcreteColors";
import * as AceColors from "../../AceColors";

const AsideWrapper = styled("div")`
  position: absolute;
  z-index: 998;
  right: 0;
  top: auto;
`;

const AsideContent = styled.div<{ maxHeight: number; maxWidth?: string }>`
  display: block;
  background-color: white;
  border: 0px solid
    ${(props: any) =>
      props.theme.baseTheme === "Ace"
        ? AceColors.gray300
        : ConcreteColors.gray300};
  box-shadow: -2px 1px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  overflow-y: auto;
  width: auto;
  max-width: 0;
  max-height: 0;
  float: left;
  transition: max-width 0.25s linear 0s, max-height 0s linear 0.25s,
    padding 0s linear 0.25s, border-width 0s linear 0.25s;

  &.show {
    max-width: ${props => (props.maxWidth ? props.maxWidth : "420px")};
    max-height: ${props => props.maxHeight}px;
    transition-delay: 0s;
  }
`;

const AsideHeader = styled("div")`
  font-size: 1.5em;
  background-color: ${(props: any) =>
    props.theme.baseTheme === "Ace" ? props.theme.navbarColor : "white"};
  color: ${(props: any) =>
    props.theme.baseTheme === "Ace" ? "white" : "black"};
  height: 40px;
  font-weight: 600;
  line-height: 1.8;
  padding-left: 0.5em;
  white-space: nowrap;
  overflow: hidden;

  & > button {
    border: 0px;
    background: none;
    opacity: 0.75;
    height: 40px;
    color: white;
    cursor: pointer;
    outline: none;
    font-size: 1.5em;
    line-height: 0.5;
    float: right;

    &:hover {
      opacity: 1;
    }
  }
`;

const AsideBody = styled("div")`
  overflow: hidden;
`;

const ModalButton = styled("button")`
  width: 42px;
  height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 6px 0px 0px 6px;
  padding: 6px 0 8px;
  line-height: 1.7;
  position: relative;
  background: repeat-x
    ${(props: any) =>
      props.theme.baseTheme === "Ace"
        ? "#ffb44b"
        : ConcreteColors.blue200} !important;
  z-index: 999;
  position: relative;
  float: left;
  outline: 0;
`;

export interface AsideProps extends React.HTMLProps<HTMLInputElement> {
  /** Title of the modal aside */
  title?: string;
  /** The icon used to close the modal aside */
  closeIcon?: string;
  /** The icon used to open the modal aside. If you want to set the same icon to both close and open state then pass this prop only */
  openIcon?: string;
  /** (Optional) The maximum width for the panel (for example, "50vw" or "500px") */
  panelMaxWidth?: string;
}

export interface AsideState {
  show: boolean;
}

export default class ModalAside extends React.Component<
  AsideProps,
  AsideState
> {
  constructor(props: AsideProps) {
    super(props);

    this.state = {
      show: false
    };
  }

  toggleClick = () => {
    this.setState(prevState => ({
      show: !prevState.show
    }));
  };

  render() {
    let maxHeight = Math.floor(window.innerHeight * 0.9);

    let close = "fa fa-minus";
    let open = "fa fa-plus";
    if (this.props.openIcon) {
      open = this.props.openIcon;
      close = this.props.closeIcon ? this.props.closeIcon : this.props.openIcon;
    }

    return (
      <AsideWrapper>
        <ModalButton onClick={this.toggleClick}>
          <i className={this.state.show ? close : open} />
        </ModalButton>

        <AsideContent
          maxHeight={maxHeight}
          className={this.state.show ? "show" : ""}
          maxWidth={this.props.panelMaxWidth}
        >
          <AsideHeader>
            {this.props.title}
            <button type="button" onClick={this.toggleClick}>
              <span className="white">&times;</span>
            </button>
          </AsideHeader>

          <AsideBody>{this.props.children}</AsideBody>
        </AsideContent>
      </AsideWrapper>
    );
  }

  static Section = AsideSection;
}
